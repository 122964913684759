<template>
  <div>
    <!-- Leaderboard Section -->
    <section class="h-auto leaderboard-bg" id="leaderboard">
      <div class="flex justify-center px-2 md:px-8 lg:pt-32">
        <div class="flex flex-col justify-center items-center mt-8 mb-14 lg:mb-16 lg:mt-0 lg:p-0 rounded-3xl">
          <!-- <div v-show="selectedLeaderboard == false" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 lg:mt-24 shadow-xl uppercase font-base tracking-wider" style="font-family: Roboto, serif;">roobet leaderboard ends every 2 weeks.</div> -->
          <!-- <div v-show="selectedLeaderboard == false" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 lg:mt-16 md:-mb-14 shadow-xl uppercase font-thin tracking-wider" style="font-family: Roboto, serif;">20% lossback for the first week on new signups</div> -->
          <!-- <div v-show="selectedLeaderboard == false" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 lg:-mt-16 shadow-xl uppercase font-thin tracking-wider" style="font-family: Roboto, serif;">Free 5$ if you sign up. 10$ if you deposit over 20$</div>
        <div v-show="selectedLeaderboard == true" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 lg:mt-24 shadow-xl uppercase font-base tracking-wider" style="font-family: Roboto, serif;">packdraw leaderboard ends every week.</div>
        <div v-show="selectedLeaderboard == true" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 lg:-mt-16 shadow-xl uppercase font-thin tracking-wider" style="font-family: Roboto, serif;">Code shib must be entered every 24 hours</div> -->
          <div class="flex flex-row justify-center items-center">
            <img v-motion-pop-visible src="../assets/leaderboard-title.png" class="lg:px-0 w-full lg:max-w-[924px]"
              alt="leaderboard" />
          </div>
          <div
            class="text-center text-white font-semibold text-[14px] md:text-[15px] px-5 py-4 bg-gradient-to-r from-[#320000] via-[#470033] to-[#00232E] tracking-widest uppercase shadow-white [text-shadow:0px_0px_8px_var(--tw-shadow-color)] rounded"
            style="font-family: Russo One, sans-serif; filter: drop-shadow(0 0 0.5rem black)">
            {{ loading == true ? "Calculating time" : roobetTimer }}
          </div>
          <div v-if="loading == true" class="text-center text-white text-2xl md:text-4xl mt-10 font-bold"
            style="font-family: Russo One, sans-serif">
            Loading Leaderboard...
          </div>
          <div v-if="loading == false" class="hidden md:block">
            <div class="flex justify-center mt-8 mb:mb-24" style="font-family: Russo One, sans-serif;">
              <table class="w-full lg:w-auto border-separate border-spacing-y-6 msm:m-[1rem]">
                <thead v-motion-slide-visible-bottom class="bg-black"
                  style="font-family: Stalinist One, system-ui; filter: drop-shadow(0 0 0.5rem black)">
                  <tr>
                    <th
                      class="px-1 md:px-[25px] lg:pr-24 lg:pl-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-left bg-[#00C2FF] text-transparent bg-clip-text">
                      Ranks
                    </th>
                    <th
                      class="px-1 md:px-[25px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center bg-[#00C2FF]  text-transparent bg-clip-text">
                      User
                    </th>
                    <th
                      class="px-1 md:px-[25px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center bg-[#00C2FF] text-transparent bg-clip-text">
                      Amount
                    </th>
                    <th
                      class="px-1 md:px-[25px] lg:pl-24 lg:pr-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-right bg-[#00C2FF] text-transparent bg-clip-text">
                      Prize
                    </th>
                  </tr>
                </thead>
                <div class="mt-4"></div>
                <tbody>
                  <tr v-motion-slide-visible-bottom v-for="(item, index) in top3" :key="index"
                    class="bg-gradient-to-r from-[#8A0000] from-0% via-[#C5028E] via-20% to-[#000] to-99%"
                    style="filter: drop-shadow(0 0 0.5rem black)">
                    <td
                      class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[24px] font-thin text-left">
                      <div class="flex flex-row items-center gap-2">
                        <div class="text-white tracking-widest" style="font-family: Stalinist One, system-ui;">
                          {{ getOrdinalSuffix(index + 1) }}
                        </div>
                      </div>
                    </td>
                    <td
                      class="px-1 md:px-[25px] lg:px-24 py-2 md:py-6 whitespace-nowrap text-[24px] text-white font-black text-center tracking-[0.2rem] shadow-md"
                      style="font-family: Russo One, sans-serif;">
                      {{ getMaskstring(item.username)}}
                    </td>
                    <td
                      class="px-1 md:px-[25px] lg:px-24 py-2 md:py-6 whitespace-nowrap text-[24px] text-white font-black text-center tracking-[0.2rem] shadow-md"
                      style="font-family: Russo One, sans-serif;">
                      ${{
                        item.weightedWagered.toFixed(2)
                      }}
                    </td>
                    <td
                      class="px-1 md:px-[25px] lg:px-8 py-2 md:py-6 whitespace-nowrap text-[25px] text-[#00FFD1] font-black text-right tracking-[0.2rem] shadow-md">
                      ${{
                        getRoobetRewardValue(index)
                      }}
                    </td>
                  </tr>
                </tbody>
                <div class="mt-8"></div>
                <tbody>
                  <tr v-motion-slide-visible-bottom v-for="(item, index) in topRest" :key="index"
                    class="bg-gradient-to-r from-[#300000] via-[#490035] to-[#001A22]"
                    style="filter: drop-shadow(0 0 0.5rem black);">
                    <td
                      class="px-1 md:px-[25px] lg:px-10 py-2 md:py-6 whitespace-nowrap text-[24px] font-semibold text-left">
                      <div class="flex flex-row items-center gap-2">
                        <div class="text-white" style="font-family: Stalinist One, system-ui;">
                          {{ getOrdinalSuffix(index + 4) }}
                        </div>
                      </div>
                    </td>
                    <td
                      class="px-1 md:px-[25px] lg:px-24 py-2 md:py-6 whitespace-nowrap text-[24px] text-white font-black text-center tracking-[0.2rem] shadow-md"
                      style="font-family: Russo One, sans-serif;">
                      {{ getMaskstring(item.username) }}
                    </td>
                    <td
                      class="px-1 md:px-[25px] lg:px-24 py-2 md:py-6 whitespace-nowrap text-[24px] text-white  font-black text-center tracking-[0.2rem] shadow-md"
                      style="font-family: Russo One, sans-serif;">
                      ${{
                        item.weightedWagered.toFixed(2)
                      }}
                    </td>
                    <td
                      class="px-1 md:px-[25px] lg:px-8 py-2 md:py-6 whitespace-nowrap text-[24px] text-[#00FFD1] font-black text-right tracking-[0.2rem] shadow-md">
                      ${{
                        getRoobetRewardValue(index + 3)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- mobile view starts here -->
          <div v-if="loading == false" class="block md:hidden">
            <div class="flex justify-center mt-8 mb:mb-24 text-xl bg-black bg-opacity-70"
              style="font-family: Inter, sans-serif;">
              <table class="w-full lg:w-auto msm:m-[1rem]">
                <thead v-motion-slide-visible-bottom>

                  <tr>
                    <th
                      class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-left">
                      Ranks
                    </th>
                    <th
                      class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                      User
                    </th>
                    <th
                      class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                      Amount
                    </th>
                    <th
                      class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-right">
                      Prize
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-motion-slide-visible-bottom v-for="(item, index) in topForMobile" :key="index"
                    style="font-family: Inter, sans-serif;">
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[16px] text-white font-bold text-left msm:font-semibold msm:py-6 msm:text-[18px]">
                      {{ getOrdinalSuffix(index + 1) }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[16px] text-white font-normal text-center msm:font-semibold msm:py-6 msm:text-[18px]">
                      {{ getMaskstring(item.username) }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[16px] text-white font-normal text-center msm:font-semibold msm:py-6 msm:text-[18px]">
                      ${{
                        item.weightedWagered.toFixed(2)
                      }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[16px] text-[#00FFD1] font-bold text-right msm:font-semibold msm:py-6 msm:text-[18px] msm:text-[#00FFD1]">
                      ${{
                        getRoobetRewardValue(index)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="text-white">
            Your wagers on Roobet will count towards the leaderboard at the
            following weights based on the games you are playing. This helps
            prevent leaderboard abuse:
            <p class="text-white">
              Games with an RTP of 97% or less will contribute 100% of the amount wagered to the leaderboard.<br>
              Games with an RTP above 97% will contribute 50% of the amount wagered to the leaderboard.<br>
              Games with an RTP of 98% and above will contribute 10% of theamount wagered to the leaderboard. <br>
              Only slots and housegames(dice excluded) count towards leaderboard.<br>
            </p>
          </div>
        </div>
      </div>
    </section>
    <PrevLeaderBoardSection ref="PrevLeaderBoardSection" />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
import PrevLeaderBoardSection from "./PrevLeaderBoardSection.vue";

export default {
  components: {
    PrevLeaderBoardSection,
  },
  data() {
    return {
      top3: [],
      topRest: [],
      topForMobile: [],

      roobetTimer: "",

      roobetStartDate: null,
      roobetEndDate: null,

      roobetNotFormattedEndDate: null,

      loading: false,
    };
  },
  methods: {
    async init() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true;
      await axios
        .get(`${baseUrl}/api/roobet/leaderboard`, {
          headers: {
            "x-api-key": process.env.VUE_APP_X_API_KEY,
          },
        })
        .then((res) => {
          this.rooberLeaderboard = res.data.leaderboard;
          this.roobetNotFormattedEndDate = res.data.endDate;

          const leaderboard = this.rooberLeaderboard;
          this.top3 = leaderboard.slice(0, 3).map((item) => {
            return item;
          });

          this.topRest = leaderboard.slice(3, 5).map((item) => {
            return item;
          });

          this.topForMobile = leaderboard.slice(0, 5).map((item) => {
            return item;
          });

          this.startRoobetTimer()
          this.loading = false;
        });
    },
    getOrdinalSuffix(n) {
      const s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getMaskstring(string) {
      if (string) {
        const firstHalf =  Math.ceil(Number(string.length / 2))
        const secondHalf = string.length - firstHalf

        return string.slice(0, firstHalf) + '*'.repeat(secondHalf)
      }

      return string
    },
    getRoobetRewardValue(rank) {
      const prizes = [500, 250, 125, 75, 50];
      return prizes[rank];
    },
    startRoobetTimer() {
      const getNextTargetDate = () => {
        const now = moment.tz("America/New_York");
        let targetDate = moment
          .tz(this.roobetNotFormattedEndDate, "America/New_York")
          .hour(23)
          .minute(59)
          .second(59)
          .subtract(1, "days");

        if (now.isAfter(targetDate)) {
          targetDate.add(1, "month");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let roobetTimerInterval;

      const updateTimer = () => {
        const nowEst = moment.tz("America/New_York");
        const distance = targetDate.diff(nowEst);

        if (distance < 0) {
          this.timer = "Leaderboard ended";
          this.init();
          this.$refs.PrevLeaderBoardSection.init();
          clearInterval(roobetTimerInterval);

          targetDate = getNextTargetDate();
          this.startRoobetTimer();
        } else {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.roobetTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      };
      updateTimer();
      roobetTimerInterval = setInterval(updateTimer, 1000);
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.leaderboard-bg {
  background-image: url("../assets/leaderboard-bg-grouped.png");
  background-repeat: no-repeat;
  background-size: cover;
}

</style>
